import React, { useState } from "react";
import HeaderRoutes from "../../components/HeaderRoutes/HeaderRoutes";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    position: 'relative',
    border: '1px solid #cccccc',
    fontSize: 16,
    width: '100%',
    height:'40px',
    paddingLeft:'13px',
  },
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily:'karla',
  color:'#000000',
  fontSize: '20px',
  fontWeight: '500',
  '&.Mui-focused': {
      color:'#000000',
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop:10,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius:14,
    },
  },
};

const names = [
  'Lorem ipsum',
  'Dolor sit amet',
  'Consectetur adipiscing',
  'sed do eiusmod',
  'sed do eiusmodd',
];
const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor:'transparent',
    '&:focus': {
      backgroundColor: '#F6EEFF', 
    },
    '&:hover': {
      backgroundColor: 'rgba(246, 238, 255, 0.4)',
    },
    '&:focus .list-item': {
        color: '#BF1D2D', 
        fontWeight:'bold', 
        fontSize:'24px',
    },
}));
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '37px',
    color: '#A5A5A5',
}));
const FilePickerInput = styled(InputBase)(({ theme }) => ({
  marginTop:'6px',
  padding: '0px 20px',
  height:'52px',
  width:'100%',
  border: '1px solid #cccccc',
  borderRadius: '5px 0 0 5px',
  color: '#A5A5A5',
  flexGrow: 1,
  fontSize: '16px',
  fontWeight:'bold',
  fontFamily: 'Karla',
}));
const FilePickerButton = styled(Button)(({ theme }) => ({
  marginTop:'6px',
  padding: '0px 28px',
  height:'52px',
  fontSize:'20px',
  fontWeight:'bold',
  backgroundColor: '#BF1D2D',
  color: 'white',
  borderRadius: '0 5px 5px 0',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#B71C1C',
  },
}));


const NewCustomer = ( { handleFileChange } ) => {
    const [dataName, setDataName] = React.useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleImageClick = (value) => {
      setSelectedValue(value);
    };

    const handleButtonClick = () => {
      document.getElementById('file-input').click();
    };
  
    const onFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      if (handleFileChange) {
        handleFileChange(event);
      }
    };

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setDataName(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    
  return (
    <>
      <HeaderRoutes />
        <Paper sx={{height:'fit-content', margin:isMobile ? '16px':'35px', padding:'28px'}}>
          <Typography style={{
              fontFamily:'Karla',
              fontStyle:'normal',
              fontSize:'20px',
              lineHeight:'150%',
              color:'#000000',
              fontWeight:500,
          }}>
            Create new customer databases
          </Typography>

          <Box sx={{mt:4}}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                  <FormControl variant="standard" sx={{width:'100%'}}>
                    <StyledInputLabel shrink htmlFor="bootstrap-input">
                      New Databases
                    </StyledInputLabel>
                      <OutlinedInput
                        sx={{height:'52px', marginTop:'30px'}}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        placeholder="New databases"
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <Box>
                <Typography style={{
                        fontFamily:'Karla',
                        fontStyle:'normal',
                        fontSize:'14px',
                        lineHeight:'150%',
                        color:'#000000',
                        fontWeight:500,
                    }}>
                    Database type
                    </Typography>
              </Box>
              <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"> 
                      <Box sx={{display:'flex', alignItems:'baseline', justifyContent:'left', marginTop:'20px'}}>
                        <div style={{marginLeft:'12px'}}>
                          <FormControlLabel
                            control={
                              <img
                                src={selectedValue === 'Live' ? '/images/after-checkbox.svg' : '/images/before-checkbox.svg'}
                                alt="Live"
                                width='27px'
                                height='27px'
                                onClick={() => handleImageClick('Live')}
                                style={{ cursor: 'pointer' }}
                              />
                            }
                          />
                        </div>
                        <p className="live-test" style={{marginRight:'30px'}}>Live</p>
                        <div style={{marginLeft:'16px'}}>
                          <FormControlLabel
                            control={
                              <img
                                src={selectedValue === 'Test' ? '/images/after-checkbox.svg' : '/images/before-checkbox.svg'}
                                alt="Test"
                                width='27px'
                                height='27px'
                                onClick={() => handleImageClick('Test')}
                                style={{ cursor: 'pointer' }}
                              />
                            }
                          />
                        </div>
                        <p className="live-test">Test</p>
                      </Box>
                    </RadioGroup>
                  </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box sx={{mt:4}}>
                  <FormControl variant="standard" sx={{width:'100%'}}>
                    <StyledInputLabel shrink htmlFor="bootstrap-input">
                      Copy from current database
                    </StyledInputLabel>
                  </FormControl>
                </Box>
                <FormControl className="form-controll" style={{marginTop:'10px', width:'100%'}}>
                    <Select
                      displayEmpty
                      value={dataName}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>
                            <img
                                src="/images/search-fade.svg"
                                alt="Database Icon"
                                style={{
                                marginRight: '10px',
                                verticalAlign: 'middle', 
                                }}
                            />
                            Copy from current database</em>;
                        }
            
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                    {names.map((name) => (
                        <CustomMenuItem key={name} value={name}>
                        <Checkbox checked={dataName.indexOf(name) > -1} />
                        <CustomListItemText primary={name}/>
                      </CustomMenuItem>
                    ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{  mt:4, display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontFamily: 'Karla',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '150%',
                    fontWeight: 500,
                    marginBottom: '8px',
                  }}
                >
                  Import CSV
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
                  <FilePickerInput
                    value={selectedFile ? selectedFile.name : 'Choose file'}
                    inputProps={{ readOnly: true }}
                  />
                  
                  <FilePickerButton variant="contained" onClick={handleButtonClick}>
                    Browse
                  </FilePickerButton>

                  <BootstrapInput
                    id="file-input" 
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                  />
                </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
    </>
  );
};

export default NewCustomer;



