import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem("tokenId");
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate("/", { state: { showToast: true, message: 'Please Login First', type: 'error' } });
        return null;
    }

    return (
        <>
            {children}
        </>
    );
};

export default ProtectedRoute;
