import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Switch, styled, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import HeaderRoutes from '../../components/HeaderRoutes/HeaderRoutes';
import ButtonTheme from '../../components/ThemeButton/ThemeButton';

export const headerLinks = [
  {
    label: 'Dashboard',
    link: '/firehouse'
  },

];
const buttonStyle = {
  backgroundColor: "#BF1D2D",
  color: "#fff",
  height: "2.125rem",
  padding: '.75rem 1rem',
  borderRadius: '0',
  textTransform: 'capitalize',
  fontFamily: 'Karla',
  fontSize: '14px',
  fontWeight: 400

};

const buttonStyle2 = {
  ...buttonStyle,
  background: "transparent",
  color: '#BF1D2D',
  border: '2px solid #BF1D2D',
  ':hover': {
    backgroundColor: "#BF1D2D",
    color: "#fff",
  }
}

const buttonStyle3 = {
  ...buttonStyle2,
  border: 'none',
}

const BoxHeader = ({ title, handleFunc, func }) => {
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

    }}>
      <div style={{ fontSize: '16px', fontWeight: 700, lineHeight: '120%' }}>
        {title}
      </div>
      <div style={{ display: 'flex', gap: '12px', }}>
        <img src='/move-fill.svg' {...handleFunc} />
        <img src='/remove-fill.svg' onClick={func} style={{ cursor: 'pointer' }} />
      </div>

    </div>
  )
}

const DynamicClock = ({ hour, minute }) => {
  if (hour >= 12) {
    hour -= 12
  }

  const hourDeg = (hour * 30)
  const minuteDeg = (minute * 6)
  return (
    <div style={{ position: 'relative' }}>
      <div id='clock' style={{ position: 'relative' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="89" viewBox="0 0 88 89" fill="none">
          <circle cx="44" cy="44" r="44" fill="#F5F5F5" />
          <circle cx="44" cy="44" r="43.8952" stroke="#3F3F3F" strokeOpacity="0.1" strokeWidth="0.209524" />
          <line x1="44.2104" y1="7.3877" x2="44.2104" y2="15.7686" stroke="#9E9FA6" strokeWidth="0.419048" />
          <line x1="44.2104" y1="71.7117" x2="44.2104" y2="80.0926" stroke="#9E9FA6" strokeWidth="0.419048" />
          <line x1="70.1898" y1="44.4736" x2="78.5707" y2="44.4736" stroke="#9E9FA6" strokeWidth="0.419048" />
          <line x1="7.75226" y1="44.4736" x2="16.1332" y2="44.4736" stroke="#9E9FA6" strokeWidth="0.419048" />
          <circle cx="44" cy="44" r="2.5" fill="#3F3DB6" stroke="white" strokeWidth="0.838095" />


          {/* <line x1="44.1053" y1="44.1593" x2="44.1053" y2="18.5974" stroke="black" strokeWidth="1.04762" stroke-linecap="round"/> */}
          {/* <line x1="43.4771" y1="44.7877" x2="25.039" y2="44.7877" stroke="black" strokeWidth="1.04762" stroke-linecap="round"/> */}
          {/* <line x1="39.7382" y1="40.9638" x2="66.9763" y2="64.8495" stroke="#3F3DB6" strokeWidth="0.419048"/> */}

        </svg>
      </div>
      <div style={{
        background: 'black',
        width: '1.2px',
        height: '24px',
        position: 'absolute',
        top: 'calc(50% - 24.6px)',
        right: 'calc(50% - .6px)',
        transform: `rotate(${minuteDeg}deg)`,
        transformOrigin: 'bottom',
      }}
      ></div>
      <div style={{
        background: 'black',
        width: '1.5px',
        height: '20px',
        position: 'absolute',
        top: 'calc(50% - 20.75px)',
        right: 'calc(50% - .75px)',
        transform: `rotate(${hourDeg + (minuteDeg / 12)}deg)`,
        transformOrigin: 'bottom ',
      }}
      ></div>

    </div>
  )
}

const DataBox = ({ text, icon }) => {
  return (
    <div style={{
      display: 'flex',
      height: '33px',
      padding: '0px 6px',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '6px',
      border: '2px solid rgba(235, 236, 240, 0.20)',
      background: '#FFF',
      boxShadow: '0px 3px 6px 0px rgba(4, 43, 141, 0.03)',

    }}>
      <img src={icon} />
      <div style={{
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '150%'
      }}>
        {text}
      </div>
    </div>
  )
}


const DateWeather = ({ date }) => {
  const day = date.toString().split(' ')[0]
  const mon = date.toString().split(' ')[1]
  const dateDay = date.toString().split(' ')[2]
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <div style={{
        color: '#181818',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 700,
        paddingBottom: '12px'
      }}>
        {day}, {mon} {dateDay}
      </div>
      <div style={{
        display: 'flex',
        gap: '6px',
        fontSize: '18px',
        fontWeight: '400',
      }}>
        <img src='/images/rainy.png'></img>
        <div>16 °</div>
      </div>

      <div style={{
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400',
      }}>
        Mostly rainy
      </div>

    </div>
  )
}

const Day = ({ day, isToday }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0px',
      textAlign: 'center',
      fontWeight: '600',
      fontSize: '.9rem',
      padding: '5px 15px',
      borderRadius: '10px',
      color: isToday === true && 'red',
      boxShadow: isToday === true && '0px 3px 6px 0px rgba(4, 43, 141, 0.03), 0px 10px 11px 0px rgba(22, 38, 61, 0.02), 0px 5px 15px 0px rgba(31, 64, 148, 0.02)',
    }}
    >
      <div>{day.toString().split(' ')[1]}</div>
      <div>{day.getDate()}</div>
    </div>
  )
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(13px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#0634FF',
      '& .MuiSwitch-thumb': {
        border: '1.5px solid white',
        boxSizing: 'border-box',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#F5F5F5',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#F5F5F5',
    boxSizing: 'border-box',
  },
}));


const FormSwitches = ({ state, setState }) => {
  const [saving, setSaving] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const handleChange = (key) => {
    setIsDisabled(false)
    setState(prev => {

      const newObj = { ...prev }
      newObj[`${key}`] = { ...newObj[`${key}`], bool: !newObj[`${key}`].bool }
      console.log('pvvvv', prev, newObj)
      return newObj
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
        {state ? Object.entries(state).map(arr => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: 700 }}>{arr[1].label}</div>
            <AntSwitch
              checked={state[arr[0]].bool}
              onChange={() => handleChange(arr[0])}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        ))
          :
          <></>
        }
        <ButtonTheme disabled={saving || isDisabled} type='submit' variant="filled" color={saving || isDisabled ? 'white' : 'primary'} className='px-5'>
          {saving && 'Saving...'}
          {!saving && 'Save'}
        </ButtonTheme>
      </div>

    </form>
  )

}

const boxStyle = {
  display: 'flex',
  width: 'fit-content',
  flexDirection: 'column',
  gap: '12px',
  maxHeight: '170px',
  padding: '24px 21px',
  borderRadius: '20px',
  border: '2px solid rgba(235, 236, 240, 0.20)',
  background: '#FFF',
  boxShadow: '0px 3px 6px 0px rgba(4, 43, 141, 0.03), 0px 10px 11px 0px rgba(22, 38, 61, 0.02), 0px 5px 15px 0px rgba(31, 64, 148, 0.02)',

}

const Time = ({ handleD, min, setMin }) => {
  const [time, setTime] = useState(new Date())
  const updateTime = () => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }

  useEffect(() => {
    updateTime()
  }, [])

  return (
    <div style={{ ...boxStyle, width: min && '170px' }}>
      <BoxHeader title='Time' handleFunc={handleD} func={() => setMin(!min)} />

      <div style={{
        display: 'flex',
        gap: '15px',
        justifyContent: min ? 'center' : 'space-between',
        alignItems: 'center',
      }}>
        <DynamicClock hour={time.getHours()} minute={time.getMinutes()} />
        {!min && <DateWeather date={time} />}

      </div>
    </div>
  )
}

const Truck = ({ handleD, min, setMin }) => {
  return (
    <div style={{ ...boxStyle, width: min ? '230px' : '500px' }}>
      <BoxHeader title='Truck of the day' handleFunc={handleD} func={() => setMin(!min)} />
      <div style={{
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        justifyContent: min && 'center'

      }}>
        <img src='/images/truck.png'></img>
        {!min &&
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            maxHeight: '130px',
          }}>
            <DataBox text='Crew 6' icon='/firefighter.svg' />
            <DataBox text='1800 Gallons' icon='/storage-tank.svg' />
            <DataBox text='1600 GPM' icon='/images/gpm.svg' />
            <DataBox text='3 Operations' icon='/fire-extinguisher.svg' />
          </div>
        }
      </div>

    </div>
  )
}

const Requests = ({ handleD, min, setMin }) => {
  const data1 = [
    { id: 0, value: 10, label: 'Approved', color: '#3b82f6' },
    { id: 1, value: 15, label: 'Declined', color: '#b81414' },
    { id: 2, value: 20, label: 'Pending', color: '#f59e0b' },
  ]

  let sum = 0
  data1.forEach(obj => sum += obj.value)

  return (
    <div style={{ ...boxStyle, width: min && '150px', maxHeight: '200px', Height: '200px', position: 'relative', padding: '24px 12px' }}>
      <BoxHeader title='Requests' handleFunc={handleD} func={() => setMin(!min)} />
      <PieChart
        series={[
          {
            innerRadius: 52,
            outerRadius: 75,
            data: data1,
          },
        ]}
        width={300}
        height={150}
        sx={{
          // background: 'pink',
        }}
        margin={{ top: 0, bottom: 0, left: 0, right: 230 }}
        slotProps={{
          padding: 0,
          legend: {
            hidden: true
          },
        }}
      />
      <div style={{ position: 'absolute', top: '50%', left: min ? '27%' : '12.2%', textAlign: 'center' }}>
        <div style={{ fontSize: '10px' }}>Total Requests</div>
        <div style={{ fontSize: '20px', fontWeight: 700 }}>{sum}</div>
      </div>

      {!min &&
        <div style={{
          position: 'absolute',
          bottom: '19%',
          left: '45%',
          width: '50%',
          // background: 'purple'
        }}>
          {data1.map((obj) => (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              marginBottom: '8px'
            }}>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <div style={{
                  width: '10px',
                  height: '10px',
                  background: obj.color,
                  borderRadius: '50%'
                }}></div>
                <div style={{ color: '404040', fontSize: '12px', fontWeight: 400 }}>
                  {obj.label}
                </div>
              </div>

              <div style={{ fontSize: '12px', fontWeight: 700 }}>{obj.value}</div>

            </div>
          ))}
        </div>
      }

    </div>
  )
}

const Schedule = ({ handleD, min, setMin }) => {
  const [time, setTime] = useState(new Date())
  const [weekDays, setWeekDays] = useState([])

  const updateTime = () => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }

  const getWeekDays = (current) => {
    const week = [];
    // Starting Monday
    current.setDate((current.getDate() - current.getDay() + 1));
    for (let i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return week;
  }

  useEffect(() => {
    updateTime()
    setWeekDays(getWeekDays(new Date()))
  }, [])

  return (
    <div style={{
      ...boxStyle,
      maxHeight: min ? '130px' : '210px',
      borderRight: '15px solid #555555',
      borderTopRightRadius: '15px',
      borderBottomRightRadius: '15px'
    }}>
      <BoxHeader title='Schedule' handleFunc={handleD} func={() => setMin(!min)} />
      {!min &&
        <div style={{ padding: '8px 0', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {weekDays.map((d) => (
            time.getDate() === d.getDate()
              ?
              <Day day={d} isToday={true} />
              :
              <Day day={d} />
          ))}
        </div>
      }
      <div style={{ display: 'flex', flexDirection: 'column', borderTop: '1px solid rgba(0,0,0, .25)', padding: '10px 0' }}>
        <div style={{ fontWeight: 700 }}>Car 3 - (Headquarters)</div>
        <div>Night shift</div>
      </div>

    </div>
  )
}

export default function Dashboard() {

  const [loading, setLoading] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  const [minimizeTime, setMinimizeTime] = useState(false);
  const [minimizeTruck, setMinimizeTruck] = useState(false);
  const [minimizeReq, setMinimizeReq] = useState(false);
  const [minimizeSch, setMinimizeSch] = useState(false);

  const showWidgets = {
    dnt: { label: 'Date and Time', bool: false },
    requests: { label: 'Requests', bool: false },
    schedule: { label: 'Schedule', bool: false },
    truck: { label: 'Truck of The Day', bool: false }
  }
  const tabs = ['Officers', 'Firefighters', 'Chief Officers']
  const [openedTab, setOpenedTab] = useState(tabs[0])
  const [officersWidgets, setOfficersWidgets] = useState(showWidgets)
  const [firemenWidgets, setFiremenWidgets] = useState(showWidgets)
  const [COWidgets, setCOWidgets] = useState(showWidgets)

  const [isModalOpened, setIsModalOpened] = useState(false)

  const [titles, setTitles] = useState(['Time', 'Truck', 'Req', 'Sch'])

  const handleDrag = (res) => {
    const { source, destination, type } = res

    if (!destination) return

    if (source.droppableId === destination.droppableId && source.index === destination.index) return

    if (type === 'group') {
      const reordered = [...titles]
      const [removed] = reordered.splice(source.index, 1)
      reordered.splice(destination.index, 0, removed)

      return setTitles(reordered)

    }
  }
  let navigate = useNavigate();
  // useEffect(() => {
  //   navigate('/firehouse/calendar')
  // }, [])

  return (
    <>
      {isModalOpened &&
        <div
          style={{
            background: 'rgba(0,0,0,.6)',
            width: '100%',
            zIndex: '1000',
            position: 'absolute',
            minHeight: '100%',
            top: '0',
            left: '0',
            paddingLeft: '160px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <div style={{ background: 'white', width: '597px', borderRadius: '5px', padding: '15px' }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px 0',
              marginBottom: '20px'
            }}>
              <h3 style={{ fontSize: '16px', fontWeight: 900, margin: 0 }}>
                Adjust Templates
              </h3>
              <div
                onClick={() => setIsModalOpened(false)}
                style={{
                  backgroundColor: '#F4F6FA',
                  borderRadius: '50%',
                  width: '28px',
                  height: '28px',
                  textAlign: 'center',
                  cursor: 'pointer'
                }}>
                <img src='/images/close.png'></img>
              </div>
            </div>
            <div style={{
              background: '#BF1D2D',
              width: '100%',
              padding: '8px 16px 0px 16px',
              display: 'flex',
              gap: '16px',
              marginBottom: '30px'
            }}
            >
              {tabs.map(t => (
                <div style={{
                  background: openedTab === t && 'white',
                  color: openedTab === t ? 'black' : 'white',
                  padding: '2px 5px',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  cursor: openedTab !== t && 'pointer',
                }}
                  onClick={() => setOpenedTab(t)}
                >
                  {t}
                </div>
              ))}
            </div>
            {openedTab === 'Officers' && <FormSwitches state={officersWidgets} setState={setOfficersWidgets} />}
            {openedTab === 'Firefighters' && <FormSwitches state={firemenWidgets} setState={setFiremenWidgets} />}
            {openedTab === 'Chief Officers' && <FormSwitches state={COWidgets} setState={setCOWidgets} />}
          </div>
        </div>
      }

      <div className="d-flex">
        <HeaderRoutes step={0} steps={headerLinks} />
      </div>
      <Paper className='my-3 p-4 mx-4' sx={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2.4rem' }}>
          <h3 style={{ fontSize: '24px', fontWeight: 700, lineHeight: '120%' }}>
            Dashboard
          </h3>
          <Grid container sx={{ display: 'flex', gap: '0.625rem', justifyContent: 'end' }}>
            <Button sx={buttonStyle3} disabled={!isAdding}>
              Save current view
            </Button>
            <Button sx={buttonStyle2} onClick={() => setIsModalOpened(true)}>
              Adjust Templates
            </Button>
            <Button sx={buttonStyle}>
              Add New
            </Button>
          </Grid>
        </div>

        {
          loading &&
          <Box className="w-100 h-100 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </Box>
        }
        {!loading &&
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId='ROOT' type='group'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: 'flex', flexWrap: 'wrap', gap: '1.2rem' }}
                >
                  {titles.map((t, i) => (
                    <Draggable draggableId={t} index={i} key={t}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}

                        >
                          {t === 'Time' && <Time handleD={{ ...provided.dragHandleProps }} min={minimizeTime} setMin={setMinimizeTime} />}
                          {t === 'Truck' && <Truck handleD={{ ...provided.dragHandleProps }} min={minimizeTruck} setMin={setMinimizeTruck} />}
                          {t === 'Req' && <Requests handleD={{ ...provided.dragHandleProps }} min={minimizeReq} setMin={setMinimizeReq} />}
                          {t === 'Sch' && <Schedule handleD={{ ...provided.dragHandleProps }} min={minimizeSch} setMin={setMinimizeSch} />}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>


            {/* <Time min={minimizeTime} setMin={setMinimizeTime} />
						<Truck min={minimizeTruck} setMin={setMinimizeTruck}/>
						<Requests min={minimizeReq} setMin={setMinimizeReq}/>
						<Schedule min={minimizeSch} setMin={setMinimizeSch}/> */}
            {/* <Droppable droppableId='ROOT' type='group'>
						{(provided)=>(
							<div {...provided.droppableProps} ref={provided.innerRef} style={{background: 'lightGreen'}}>
								{data1.map((el,ind) => (
									<Draggable draggableId={el.id.toString()} index={ind} key={el.id}>
										{(provided)=>(
										<div
											{...provided.dragHandleProps}
											{...provided.draggableProps}
											ref={provided.innerRef}
											style={{...provided.draggableProps.style, background: el.color}}
										>
										{el.label}
										</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable> */}
          </DragDropContext>



        }
      </Paper>

    </>
  );
}
