import React from 'react'
import { Layout } from 'react-admin';
import MyAppBar from './AppBar';
import { MyMenu } from './Menu';
import { Box } from '@mui/material';

export const MyLayout = props =>
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} >
    <Box sx={{ flexGrow: 1, marginTop: "0px" }}>
      {React.Children.only(props.children)}
    </Box>
  </Layout>