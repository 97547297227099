import React from "react";
import { useSidebarState } from "react-admin";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MyAppBar = (props) => {
    const [open, setOpen] = useSidebarState(true);
    const navigate = useNavigate();
    const handleLogout = () =>{
        navigate("/")
    }

    return (
        <div onClick={() => setOpen(!open)} style={{minHeight:'98px', 
        position:'static',
        padding:'26px 20px', 
        backgroundImage:'linear-gradient(to right, rgba(33, 33, 33, 1), rgba(40, 42, 60, 1), rgba(36, 44, 46, 1), rgba(33, 33, 33, 1) )'}} 
        className="d-md-none">
            <div style={{display:'flex', alignItems:'center' , justifyContent:'space-between'}}>
                <Box sx={{ cursor:'pointer' }}>
                    <Box component="img" src="/images/small-logo.svg" alt="Services" />
                </Box>
                <div style={{display:'flex', alignItems:'center' , justifyContent:'space-between'}}>
                <Box sx={{ flexGrow: 0, mr:2, cursor:'pointer' }}>
                    <Box component="img" src="/images/notification.svg" alt="Services"/>
                </Box>
                <Box sx={{ flexGrow: 0, mr:2, cursor:'pointer' }}>
                    <Box component="img" src="/images/settings.svg" alt="Services" />
                </Box>
                <Box sx={{ flexGrow: 0, mr:2, cursor:'pointer' }}>
                    <Box 
                    component="img" 
                    src="/images/logout.svg" 
                    alt="Services"
                    onClick={handleLogout}
                    />
                </Box>
                <Box sx={{ flexGrow: 0, mr:2, cursor:'pointer' }}>
                    <Box component="img" src="/images/sidebar-icon.svg" alt="Services" />
                </Box>
                </div>
            </div>
        </div> 
    );
};
export default MyAppBar;
