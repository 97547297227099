import React from "react";
import { Admin, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import { MyLayout } from "../../components/Layout/Layout";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "../../components/Theme/Theme";
import "../../components/Layout/layout.css";
import Calendar from "../Calendar/calendar";
import Dashboard from "../Dashboard/dashboard";
import Database from "../Database/Database";
import CopyDatabase from "../CopyDatabase/CopyDatabase";
import NewCustomer from "../NewCustomer/NewCustomer";
import DatabaseOverview from "../DBS/DatabaseOverview";
import UpdateVariables from "../UpdateVariables/UpdateVariables";

export default function Home() {
  return (
    <ThemeProvider theme={Theme}>
      <Admin theme={Theme} layout={MyLayout} basename="/firehouse">
        <CustomRoutes>
          <Route path="/firehouse/dashboard" element={<Dashboard />} />
          <Route path="/firehouse/calendar" element={<Calendar />} />
          <Route path="/firehouse/database" element={<Database />} />
          <Route path="/firehouse/copy-database" element={<CopyDatabase />} />
          <Route path="/firehouse/new-customer" element={<NewCustomer />} />
          <Route path="/firehouse/database-overview" element={<DatabaseOverview/>} />
          <Route path="/firehouse/update-variables" element={<UpdateVariables/>} />
        </CustomRoutes>
      </Admin>
    </ThemeProvider>
  );
}
