import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import './HeaderRoutes.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../../config/config';



export default function HeaderRoutes() {
  const navigate = useNavigate();
  const [authStatus, setAuthStatus] = useState(null);
  const [error, setError] = useState(null); 

  useEffect(() => {
    const authMe = async () => {
      try {
        const path = `${config.authServiceUrl}auth/me`;
        const token = localStorage.getItem("tokenId");
        
        const response = await axios.get(path, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            // 'Referer':'https://test02.firehouse247.com/firehouse/calendar',
          }
        });

        if (response.status === 200) {
          setAuthStatus('Authenticated');
          console.log('Auth-me', response);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setAuthStatus(error.response.data.message);
          setError('Unauthorized access. Please log in.', error.response.data.message);
          setTimeout(() => {
            navigate("/")
          }, 1000);
        } else {
          setError('An error occurred.');
        }
      }
    };
    authMe();
  }, []); 

  const handleLogout = () => {
    let path = config.authServiceUrl;
    path = path + 'logout';
  
    const token = localStorage.getItem("tokenId");
    axios.get(path, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        localStorage.removeItem("tokenId")
        setTimeout(() => {
          navigate("/")
        }, 1000);
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log('logout failed', error.response?.data?.message || error.message);
      });
  };
  

  return (
    <>
      {/* <Paper className="main-nav" elevation={0}>
        <BottomNavigation
          sx={{
            overflow: "auto",
            height: "auto",
            minHeight: "98px",
            "& .MuiButtonBase-root": {
              maxWidth: "unset",
              minWidth: "unset",
            },
          }}
          className=" flex-wrap justify-content-start scroll-bar"
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {steps &&
            steps.map((item, index) => {
              if (item.hide) {
                return;
              }
              if (item.disable) {
                return (
                  <BottomNavigationAction
                    key={index + "navi"}
                    sx={{
                      marginBottom: "4px",
                      color: "#555555",
                      "& .MuiBottomNavigationAction-label":
                      {
                        whiteSpace:
                          "nowrap !important",
                        fontWeight: "500",
                        fontSize: "1rem !important",
                      },
                      "& .Mui-selected": {
                        color: "#BF1D2D",
                        fontSize: "1rem !important",
                      },
                      ".Mui-selected&": {
                        color: "#BF1D2D",
                        borderTop: "solid 2px #BF1D2D",
                      },
                    }}
                    label={item.label}
                    disabled
                    className="mx-3 p-0"
                  />
                );
              }
              return (
                <BottomNavigationAction
                  key={index + "navi"}
                  sx={{
                    marginBottom: "4px",
                    color: "#555555",
                    "& .MuiBottomNavigationAction-label": {
                      whiteSpace: "nowrap !important",
                      fontWeight: "500",
                      fontSize: "1rem !important",
                    },
                    "& .Mui-selected": {
                      color: "#BF1D2D",
                      fontSize: "1rem !important",
                    },
                    ".Mui-selected&": {
                      color: "#BF1D2D",
                      borderTop: "solid 2px #BF1D2D",
                    },
                  }}
                  label={item.label}
                  component={Link}
                  to={item.link}
                  className="mx-3 p-0"
                />
              );
            })}
        </BottomNavigation>
      </Paper> */}

      <AppBar position="sticky"  className='app-bar' style={{height:'84px', 
        backgroundImage:'linear-gradient(to right, rgba(33, 33, 33, 1), rgba(40, 42, 60, 1), rgba(36, 44, 46, 1), rgba(33, 33, 33, 1) )'}} >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
                      
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  className='pages-content'
                  sx={{ my: 4, color: 'white', display: 'block'}}
                >
                  <Box component="img" src="/images/over-view.svg" alt="Overview" sx={{pr:1}}/>
                  Overview
                </Button>

                <Button
                  className='pages-content'
                  sx={{ my: 4, color: 'white', display: 'block'}}
                >
                  <Box component="img" src="/images/department.svg" alt="Department" sx={{pr:1}}/>
                  Department
                </Button>

                <Button
                  className='pages-content'
                  sx={{ my: 4, color: 'white', display: 'block'}}
                >
                  <Box component="img" src="/images/services.svg" alt="Services" sx={{pr:1}}/>
                  Services
                </Button>
            </Box>  */}

              <div className='header-menu'>
              <Box sx={{ flexGrow: 0, mr:4, my:3, cursor:'pointer'}}>
                  <Box component="img" src="/images/notification.svg" alt="Services"/>
              </Box>

              <Box sx={{ flexGrow: 0, mr:4,my:3, cursor:'pointer' }}>
                  <Box component="img" src="/images/settings.svg" alt="Services" />
              </Box>

              <Box sx={{ flexGrow: 0, mr:4,my:3, cursor:'pointer' }}>
                  <Box 
                  component="img" 
                  src="/images/logout.svg" 
                  alt="Services"
                  onClick={handleLogout}
                  />
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                  <IconButton  sx={{ p: 0, my:3 }}>
                    <Avatar alt="Remy Sharp" src="/images/Ellipse 1.svg" />
                  </IconButton>
              </Box>
              </div>
          </Toolbar>
        </Container>
      </AppBar>

    </>
  );
}
