import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Google from "./Google";
import './SocialLogin.css';


const SocialLogin = () => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" marginTop="15px" gap="30px">
        <button type="button" className=" bg-transparent border-0 ">
          <div className="google">
          <img src="/images/google.svg"/>
            <p className="pt-3 px-0 sign-in">Sign In with Google</p>
          </div>
          <div className="googlee">
            <img src="/images/google 2.svg"/>
            <p className="pt-3 px-2 sign-inn">Sign In with Google</p>
          </div>
        </button>
      </Box>
    </>
  );
};

export default SocialLogin;