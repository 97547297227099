import { blue, grey } from '@material-ui/core/colors';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const Theme = createTheme({
  sidebar: {
    width: 262,
  },
  typography: {
    fontFamily: 'Karla, Roboto , inter, Arial , sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#E62719',
      light: '#E62719',
      dark: '#E62719',
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[500],
      light: grey[500],
      dark: grey[500],
      contrastText: '#ffffff',
    },
    transparent: {
      main: 'transparent',
      light: 'transparent',
      dark: 'transparent',
      contrastText: '#000',
    },
    lightGreen: {
      main: '#E1EDE8',
      light: '#E1EDE8',
      dark: '#E1EDE8',
      contrastText: '#000',
    },
    lightRed: {
      main: '#EDE1E1',
      light: '#EDE1E1',
      dark: '#EDE1E1',
      contrastText: '#000',
    },
    DarkRed: {
      main: '#BF1D2D',
      light: '#E62719',
      dark: '#BF1D2D',
      contrastText: '#fff',
    },
    blue: {
      main: '#143EF6',
      light: '#143EF6',
      dark: '#143EF6',
      contrastText: '#fff',
    },
    red: {
      main: '#E62719',
      light: '#E62719',
      dark: '#E62719',
      contrastText: '#fff',
    }
  },
});

export default Theme;
