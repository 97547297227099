import DataTable from "react-data-table-component";
import ButtonTheme from "../../components/ThemeButton/ThemeButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import "./Database.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  boxShadow: "0px 0px 16px 0px #00000040",
  borderRadius: "10px",
  p: 4,
};

export default function Database() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showSearch2, setShowSearch2] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };
  const toggleSearch2 = () => {
    setShowSearch2(!showSearch2);
  };

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(row.name)) {
        return prevSelectedRows.filter((name) => name !== row.name);
      } else {
        return [...prevSelectedRows, row.name];
      }
    });
  };
  const liveColumns = [
    {
      name: "Status",
      cell: (row) => <div className="status-green"></div>,
      width: "12%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <div className={selectedRows.includes(row.name) ? "red-text" : ""}>
          {row.name}
        </div>
      ),
      width: "25%",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      cell: (row) => (
        <div className={selectedRows.includes(row.name) ? "red-text" : ""}>
          {row.location}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.selected}
          className="table-checkbox"
          onChange={() => handleCheckboxChange(row)}
        />
      ),
      width: "12%",
    },
  ];

  const testColumns = [
    {
      name: "Status",
      cell: (row) => <div className="status-yellow"></div>,
      width: "10%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <div className={selectedRows.includes(row.name) ? "red-text" : ""}>
          {row.name}
        </div>
      ),
      width: "25%",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      cell: (row) => (
        <div className={selectedRows.includes(row.name) ? "red-text" : ""}>
          {row.location}
        </div>
      ),
    },
    {
      name: "Lock",
      cell: (row) => (
        <div>
          <img src="/images/lock.svg" />
        </div>
      ),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.selected}
          className="table-checkbox"
          onChange={() => handleCheckboxChange(row)}
        />
      ),
      width: "12%",
    },
  ];

  const liveData = [
    {
      name: "_UAT04",
      location: "JOHNSTON RI LOCAL 1950",
    },
    {
      name: "_UAT05",
      location: "JOHNSTON RI LOCAL 1951",
    },
    {
      name: "_UAT06",
      location: "JOHNSTON RI LOCAL 1952",
    },
    {
      name: "_UAT07",
      location: "JOHNSTON RI LOCAL 1953",
    },
    {
      name: "_UAT08",
      location: "JOHNSTON RI LOCAL 1954",
    },
    {
      name: "_UAT09",
      location: "JOHNSTON RI LOCAL 1955",
    },
    {
      name: "_UAT04",
      location: "JOHNSTON RI LOCAL 1956",
    },
  ];

  const testData = [
    { name: "TEST_1", location: "JOHNSTON RI LOCAL 1950" },
    { name: "TEST_2", location: "JOHNSTON RI LOCAL 1951" },
    { name: "TEST_3", location: "JOHNSTON RI LOCAL 1952" },
    { name: "TEST_4", location: "JOHNSTON RI LOCAL 1953" },
    { name: "TEST_5", location: "JOHNSTON RI LOCAL 1954" },
    { name: "TEST_6", location: "JOHNSTON RI LOCAL 1955" },
    { name: "TEST_7", location: "JOHNSTON RI LOCAL 1956" },
  ];

  return (
    <>
      <div className="m-5">
        <h3 style={{ fontSize: "24px", fontWeight: 700, lineHeight: "120%" }}>
          Copy Database
        </h3>
        <div className="tables-container">
          <div className="table-section">
            <div
              className="d-flex justify-content-between align-items-center px-4 py-2"
              style={{ borderBottom: "1px solid #DDDDDD" }}
            >
              <h2>Live Databases</h2>
              {showSearch ? (
                <div className="search-bar">
                  <input type="text" placeholder="Search" />
                  <img
                    src="/images/search.svg"
                    width={20}
                    height={20}
                    onClick={toggleSearch}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <img
                  src="/images/search.svg"
                  width={20}
                  height={20}
                  onClick={toggleSearch}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            <DataTable
              columns={liveColumns}
              data={liveData}
              responsive
              className="live_table"
            />
          </div>
          <div className="table-section">
            <div
              className="d-flex justify-content-between align-items-center px-4 py-2"
              style={{ borderBottom: "1px solid #DDDDDD" }}
            >
              <h2>Test Databases</h2>
              {showSearch2 ? (
                <div className="search-bar">
                  <input type="text" placeholder="Search" />
                  <img
                    src="/images/search.svg"
                    width={20}
                    height={20}
                    onClick={toggleSearch2}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <img
                  src="/images/search.svg"
                  width={20}
                  height={20}
                  onClick={toggleSearch2}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            <DataTable
              columns={testColumns}
              data={testData}
              responsive
              className="live_table"
            />
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center mt-4 ps-2">
          <input
            type="checkbox"
            className="table-checkbox"
            width={30}
            height={30}
          />
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "21px",
              // fontFamily: "karla",
              margin: "0px",
            }}
          >
            LOCK DB
          </p>
          <img src="/images/Icon.svg" />
          <input
            type="text"
            placeholder="Note :-"
            className="flex-grow-1 note_input"
          />
        </div>
        <ButtonTheme
          color="DarkRed"
          style={{
            borderRadius: "80px",
            padding: "13px 39px",
            marginTop: "32px",
          }}
          onClick={handleOpen}
        >
          COPY DATABASE
        </ButtonTheme>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <textarea
              type="text"
              placeholder="Type DB name copying to,verify the copy"
              className="modal_textarea"
              rows="2"
            />
            <div className="d-flex justify-content-end gap-4 align-items-center mt-3">
              <p
                className="m-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                Cancel
              </p>
              <ButtonTheme
                color="DarkRed"
                style={{
                  borderRadius: "80px",
                  padding: "10px 24px",
                  fontSize: "14px",
                }}
                onClick={handleClose}
              >
                CONFIRM
              </ButtonTheme>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
