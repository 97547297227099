import { Box, Grid, Typography } from "@mui/material";
import "./calendar.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment/moment";

const Calendar = () => {
  let navigate = useNavigate();
  const [calendar, setCalendar] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const routeDate = searchParams.get('date');

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <Grid className="m-0 mt-3" container spacing={2} columns={{ xs: 5, lg: 10 }} backgroundColor="#fff">
          <Grid item xs={5} lg={6} className='p-1'>
            <Box backgroundColor="#fff">
              <Box display="flex" flexWrap="wrap" borderTop="1px solid #eee" borderBottom="1px solid #eee">
                <div className="calendar-header-item">
                  <Typography fontWeight="600">SUN</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">MON</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">TUE</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">WED</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">THUR</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">FRI</Typography>
                </div>
                <div className="calendar-header-item">
                  <Typography fontWeight="600">SAT</Typography>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Calendar;
