import React from 'react';
import HeaderRoutes from '../../components/HeaderRoutes/HeaderRoutes';
import SearchBox from "../../components/SearchBox/SearchBox";
import { Box, Grid, Paper, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Dept-1',
  'Dept-2',
  'Dept-3'
];

function getStyles(name, departmentName, theme) {
  return {
    fontWeight:
      departmentName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateVariables = () => {
  const theme = useTheme();
  const [departmentName, setDepartmentName] = React.useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event) => {
    const { target: { value } } = event;
    setDepartmentName(typeof value === 'string' ? value.split(',') : value);
  };

  const baseColumns = [
    {   
      field: '', 
      headerName: '', 
      width: 70 , 
      renderCell: () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box component="img" src="/images/blue-icon.svg" alt="icon" />
        </Box>
      ),
    },
    { 
      field: 'variable_name', 
      headerName: 'Variable name', 
      width: 200 
    },
    { 
      field: 'Department_1', 
      headerName: 'Department-1', 
      width: 200 
    },
    { 
      field: 'Department_2', 
      headerName: 'Department-2', 
      width: 200 
    },
    { 
      field: 'Department_3', 
      headerName: 'Department-3', 
      width: 200 
    },
  ];

  const filteredColumns = baseColumns.filter((column) => {
    if (column.field === 'variable_name' || column.field === '') return true; 
    if (departmentName.includes('Dept-1') && column.field === 'Department_1') return true;
    if (departmentName.includes('Dept-2') && column.field === 'Department_2') return true;
    if (departmentName.includes('Dept-3') && column.field === 'Department_3') return true;
    return false;
  });

  const rows = [
    { id: 1, variable_name: 'Variable 1', Department_1: 'Value 1', Department_2: 'Value 2', Department_3: 'Value 3' },
    { id: 2, variable_name: 'Variable 2', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 3, variable_name: 'Variable 3', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 4, variable_name: 'Variable 4', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 5, variable_name: 'Variable 5', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 6, variable_name: 'Variable 6', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 7, variable_name: 'Variable 2', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 8, variable_name: 'Variable 2', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 9, variable_name: 'Variable 2', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
    { id: 10, variable_name: 'Variable 2', Department_1: 'Value A', Department_2: 'Value B', Department_3: 'Value C' },
  ];

  return (
    <>
      <HeaderRoutes />
      <Paper className="m-4 px-4 pt-4" style={{ overflowX: 'auto', maxWidth: isMobile ? '86vw' : '' }}>
        <div>
          <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                Update Variables
              </Typography>

              <Box sx={{ my: 3 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Please Select the Department</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={departmentName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Please Select the Department" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, departmentName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <SearchBox style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Box>

              {departmentName.length === 0 ? (
                <Box sx={{ my: 3 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ padding: isMobile ? '16px' : '22px', border: '1px solid #cccccc', borderRadius: '4px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#2E3238' }}>
                          Please select department to view variables
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', height: isMobile ? '20vh' : '60vh' }}>
                          <img src='/images/select-variable.svg' alt='Select Variable' />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ my: 3 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ border: '1px solid #cccccc', borderRadius: '4px' }}>
                        <Box sx={{ height: 400, width: '100%' }}>
                          <DataGrid
                            rows={rows}
                            columns={filteredColumns}
                            sx={{ border: 0 }}
                            hideFooter
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

export default UpdateVariables;
