import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Toast = ({ snackbarOpen, handleCloseSnackbar, message, type }) => {
  const backgroundColor = type === 'success' ? 'green' : '#BF1D2D';

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
      message={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: backgroundColor,
          color: 'white',
        },
      }}
      action={
        <React.Fragment>
          <IconButton
            size="large"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default Toast;
