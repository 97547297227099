import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Box, Snackbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import './login.styles.css';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate } from "react-router-dom";
import config from '../../config/config';
import axios from 'axios';
import Toast from '../../config/Toast';

export default function Login() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showDigitCode, setShowDigitCode] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => setShowPassword((show) => !show);
  const handleForgotPasswordClick = () => setShowForgotPassword(true);
  const handleShowDigitCode = () => setShowDigitCode(true);
  const handleShowNewPassword = () => setNewPassword(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("")
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    }
    let path = config.authServiceUrl;
    path = path + 'login';
    setLoading(true);
    axios.post(path, data, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    })
      .then((response) => {
        localStorage.setItem("tokenId", response.data.data.access_token);
        setMessage('Login successful!',);
        setType('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/firehouse/copy-database")
        }, 1000);
        console.log("Login successful:", response);
      })
      .catch((error) => {
        console.log('login Failed', error.response.data.message);
        setLoading(false);
        setError(error);
        setMessage(error.response.data.message || 'Login failed');
        setType('error');
        setSnackbarOpen(true);
      });
  }

  const handleForgotPassword = async () => {
    const data = {
      email: email,
    }
    let path = config.authServiceUrl;
    path = path + 'forgot-password';
    setLoading(true);
    axios.post(path, data,)
      .then((response) => {
        setMessage('Password send Successfully', response.data.message);
        setType('success');
        setSnackbarOpen(true);
        console.log("Forgot Password Api Successful:", response);
      })
      .catch((error) => {
        console.log('Forgot password api failed:', error.response.data.message);
        setLoading(false);
        setError(error);
        setMessage(error.response.data.message);
        setType('error');
        setSnackbarOpen(true);
      });
  }
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    if (location.state?.showToast) {
      setSnackbarOpen(true);
      setMessage(location.state.message);
      setType(location.state.type);
    }
  }, [location.state]);

  return (
    <>
      <div className='main-login'>
        <div className='login-content p-5 p-md-0'>
          <div className='mainIconBox'>
            <img src='/images/main-icon2.svg' alt="Main Icon" />
          </div>
          <div className='mainIconBox-1'>
            <img src='/images/main-icon-2.svg' alt="Main Icon" />
          </div>
          <div className='login-width flex-grow-1'>
            <div className='pt-0'>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {showForgotPassword ? (
                  showDigitCode ? (
                    newPassword ? (
                      <div className='login-box-1'>
                        <>
                          <Grid xs={12}>
                            <Box className="forgot">
                              <h2>Set new Password</h2>
                            </Box>
                            <Box className="Email">
                              <p>Must be atleast 8 Character</p>
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              className='w-100 textField'
                              placeholder="Password"
                              label="Password"
                              variant="standard"
                              autoFocus
                              InputLabelProps={{
                                style: { color: 'white' },
                                className: 'textfeild_white'
                              }}
                              InputProps={{
                                style: { color: 'white', marginBottom: '10px' },
                                className: 'textfeild_white',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={togglePasswordVisibility}
                                      style={{ marginRight: "-5px", color: 'white' }}
                                      aria-label="toggle password visibility"
                                    >
                                      {showPassword ? <Visibility sx={{ color: 'white' }} /> : <VisibilityOff sx={{ color: 'white' }} />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              className='w-100 textField'
                              placeholder="Confirm Password"
                              label="Confirm Password"
                              variant="standard"
                              type={showPassword ? 'text' : 'password'}
                              InputLabelProps={{
                                style: { color: 'white' },
                                className: 'textfeild_white'
                              }}
                              InputProps={{
                                style: { color: 'white', marginBottom: '10px' },
                                className: 'textfeild_white',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={togglePasswordVisibility}
                                      style={{ marginRight: "-5px", color: 'white' }}
                                      aria-label="toggle password visibility"
                                    >
                                      {showPassword ? <Visibility sx={{ color: 'white' }} /> : <VisibilityOff sx={{ color: 'white' }} />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                              <Button
                                className='rounded-5 text-capitalize button-error'
                                type='submit'
                                fullWidth={true}
                                variant="contained"
                                disabled={loading}
                                style={{ backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: 'Bold' }}
                              >
                                Set new Password
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      </div>
                    ) : (
                      <div className='login-box-2'>
                        <>
                          <Grid xs={12}>
                            <Box className="forgot">
                              <h2>Enter Your Code</h2>
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <Box className="Email">
                              <p>We Sent a code to
                                <a href='' className="mx-1">{email}</a>
                              </p>
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <Box sx={{ mt: 4, textAlign: 'center' }} className="forgott">
                              {otp.map((digit, index) => (
                                <FormControl variant="outlined" key={index}>
                                  <OutlinedInput
                                    className="outlined-input"
                                    value={digit}
                                    onChange={(e) => handleChange(e, index)}
                                    inputRef={(el) => inputRefs.current[index] = el}
                                    inputProps={{
                                      style: { color: 'white', textAlign: 'center', fontWeight: '600', fontSize: '22px' },
                                      maxLength: 1,
                                    }}
                                  />
                                </FormControl>
                              ))}
                            </Box>

                            <Box className="Email-1 pt-2">
                              <p>Didn't receive the email
                                <a onClick={handleForgotPasswordClick} href='' className="mx-1">Click to resend</a>
                              </p>
                            </Box>

                            <Box sx={{ textAlign: 'center' }}>
                              <Button
                                className='rounded-5 text-capitalize button-error'
                                type='submit'
                                fullWidth={true}
                                variant="contained"
                                disabled={loading}
                                onClick={handleShowNewPassword}
                                style={{ backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: 'Bold' }}
                              >
                                Continue
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      </div>
                    )
                  ) : (
                    <div className='login-box-3'>
                      <>
                        <Grid xs={12} >
                          <Box className="forgot">
                            <h2>Forgot Password?</h2>
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <Box sx={{ my: 2 }}>
                            <TextField
                              className='w-100 textField'
                              placeholder="Enter your Email"
                              label="Email"
                              variant="standard"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              InputLabelProps={{
                                style: { color: 'white' },
                                className: 'textfeild_white'
                              }}
                              InputProps={{
                                style: { color: 'white', marginBottom: '10px' },
                                className: 'textfeild_white',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="./images/user-name-icon.svg" alt="icon" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box sx={{ textAlign: 'center' }}>
                            <Button
                              className='rounded-5 text-capitalize button-error'
                              type='submit'
                              fullWidth={true}
                              variant="contained"
                              disabled={loading}
                              onClick={() => {
                                handleForgotPassword();
                              }}
                              style={{ backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: 'Bold' }}
                            >
                              Send 4 Digit Code
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    </div>
                  )
                ) : (
                  <div className='login-box-4'>
                    <>
                      <Grid xs={12}>
                        <Box className='img-1' textAlign='center'>
                          <img src='/images/main-icon.svg' alt="Main Icon" />
                        </Box>
                        <Box className='img-2' textAlign='center'>
                          <img src='/images/main-icon-1.svg' alt="Main Icon" />
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          className='w-100 textField'
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label="Email"
                          variant="standard"
                          required
                          InputLabelProps={{
                            style: { color: 'white' },
                            className: 'textfeild_white'
                          }}
                          InputProps={{
                            style: { color: 'white', marginBottom: '10px' },
                            className: 'textfeild_white',
                            endAdornment: (
                              <InputAdornment position="end">
                                <img src="./images/user-name-icon.svg" alt="icon" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          className='w-100 textField'
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          label="Password"
                          variant="standard"
                          required
                          type={showPassword ? 'text' : 'password'}
                          InputLabelProps={{
                            style: { color: 'white' },
                            className: 'textfeild_white'
                          }}
                          InputProps={{
                            style: { color: 'white', marginBottom: '10px' },
                            className: 'textfeild_white',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                  style={{ marginRight: "-5px", color: 'white' }}
                                  aria-label="toggle password visibility"
                                >
                                  {showPassword ? <Visibility sx={{ color: 'white' }} /> : <VisibilityOff sx={{ color: 'white' }} />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid xs={12}>
                        <Box sx={{ textAlign: 'center' }}>
                          <a
                            className='textfeild_white forgot-pass'
                            onClick={handleForgotPasswordClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Forgot Password
                          </a>
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <Box sx={{ textAlign: 'center' }}>
                          <Button
                            className='rounded-5 text-capitalize button-error'
                            type='submit'
                            fullWidth={true}
                            variant="contained"
                            disabled={loading}
                            onClick={() => {
                              handleLogin()
                            }}
                            style={{ backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: 'Bold', cursor: 'pointer' }}
                          >
                            {loading ? 'Loading' : 'Login Now'}
                          </Button>
                        </Box>
                      </Grid>
                      <SocialLogin />
                    </>
                  </div>
                )}
                <Toast
                  snackbarOpen={snackbarOpen}
                  handleCloseSnackbar={handleCloseSnackbar}
                  message={message}
                  type={type}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
