
import React, { useState, useEffect } from 'react';
import { Typography, Box, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import HeaderRoutes from '../../components/HeaderRoutes/HeaderRoutes';
import SearchBox from '../../components/SearchBox/SearchBox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import config from '../../config/config';
import Toast from '../../config/Toast';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';


const DatabaseOverview = () => {
  const [database, setDatabase] = useState("ALL");
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');


  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Db Id',
      minWidth: 70,
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Db Id</span>,
      renderCell: (params) => <span style={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: 700 }}>{params.value}</span>
    },
    {
      field: 'department_name',
      headerName: 'DB Name',
      minWidth: 220,
      cellClassName: 'space-cutter-class',
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>DB Name</span>,
      renderCell: (params) => <span style={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: 700 }}>{params.value}</span>
    },
    {
      field: 'db_copied_to',
      headerName: 'DB Copied to',
      minWidth: 150,
      cellClassName: 'space-cutter-class',
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>DB Copied to</span>,
      renderCell: (params) => <span style={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: 700, color: '#BF1D2D' }}>{
        params?.row?.tenant_type === "LIVE" ? (params?.row?.db_overview_copied_to?.to_db) ? params?.row?.db_overview_copied_to?.to_db : "No Copy" : ""
      }</span>
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated',
      minWidth: 180,
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Last Updated</span>,
      renderCell: (params) => <span style={{ fontSize: '14px', fontFamily: 'Open Sans' }}>
        {(params?.row?.db_overview?.created_at)
          ?
          moment(params?.row?.db_overview?.created_at).format("DD-MMM-YYYY HH:mm")
          :
          (params?.row?.db_overview_copied_to?.created_at) ?
            moment(params?.row?.db_overview_copied_to?.created_at).format("DD-MMM-YYYY HH:mm")
            :
            ""
        }
      </span>
    },
    {
      field: 'tenancy_db_username',
      headerName: 'DB Owner',
      minWidth: 160,
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>DB Owner</span>,
      renderCell: (params) => <span style={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: 700, color: '#BF1D2D' }}>{
        params?.row?.tenant_type === "TEST" ? (params?.row?.db_overview?.user?.name) ? params?.row?.db_overview?.user?.name : "Not copied" : "Firehouse247"
      }</span>
    },
    {
      field: 'tenant_type',
      headerName: 'Type',
      minWidth: 170,
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Type</span>,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box
            component="img"
            src={params.value === "LIVE" ? '/images/live-label.svg' : '/images/test-label.svg'}
          />
        </Box>
      )
    },
    {
      field: 'lock',
      headerName: 'Locked?',
      minWidth: 20,
      renderHeader: () => <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Locked?</span>,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          {params?.row?.tenant_type === "TEST" ? <LockData params={params} /> : ''}
        </Box>
      )
    },
  ];
  const fetchData = async (page) => {
    const data = {
      type: database,
      search: search,
      page: page,
      per_page: perPage,
    };

    let path = `${config.authServiceUrl}database/overview`;
    const token = localStorage.getItem("tokenId");

    try {
      const response = await axios.post(path, data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      setRows(response.data.data);
      console.log(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotal(response.data.total);
      setPerPage(response.data.per_page);
    } catch (error) {
      console.log('Error:', error.response.data.message);
    }
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [database, search, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleChange = (event) => {
    setDatabase(event.target.value);
  };
  const handleSearch = (value) => {
    setCurrentPage(1)
    setSearch(value);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const LockData = ({ params }) => {
    const [open, setOpen] = useState(false);
    const isLocked = params?.row?.db_overview?.is_locked === 1;

    const handleUnlock = async () => {
        const id = params?.row?.id;

        if (params?.row?.is_lock_removable === true) {
            try {
                const token = localStorage.getItem("tokenId");
                const response = await axios.post(`${config.authServiceUrl}database/unlock`, { id }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                console.log("Unlocking row with ID:", id);
                setMessage('Unlock Successfully');
                setType('success');
                setSnackbarOpen(true);
                handleRefresh();
            } catch (error) {
                console.log('Error:', error.response?.data?.message);
                setMessage(error.response?.data?.message || 'Error unlocking database');
                setType('error');
                setSnackbarOpen(true);
            }
        } 
        else{ 
          {params?.row?.db_overview?.notes === null ?
            (
              setMessage(`Locked by ${params?.row?.db_overview?.user?.name}`)
            ) :
              setMessage(`Locked by ${params?.row?.db_overview?.user?.name} with a note ${params?.row?.db_overview?.notes}`);
          } 
          setType('error');
          setSnackbarOpen(true);
        }
        setOpen(false);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
    };

    const handleClickLock = (event) => {
        event.stopPropagation();
        if (params?.row?.is_lock_removable === true) {
            setOpen(true);
        } 
        else{ 
          {params?.row?.db_overview?.notes === null ?
            (
              setMessage(`Locked by ${params?.row?.db_overview?.user?.name}`)
            ) :
              setMessage(`Locked by ${params?.row?.db_overview?.user?.name} with a note ${params?.row?.db_overview?.notes}`);
          } 
          setType('error');
          setSnackbarOpen(true);
        }
    };

    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {isLocked ? (
                <>
                  {params?.row?.db_overview?.notes === null ? (
                    <Tooltip title={`Locked by ${params?.row?.db_overview?.user?.name}`} placement="top" arrow>
                      <Box
                        component="img"
                        src="/images/lock.svg"
                        onClick={handleClickLock}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Locked by ${params?.row?.db_overview?.user?.name} with a note ${params?.row?.db_overview?.notes}`} placement="top" arrow>
                      <Box
                        component="img"
                        src="/images/lock.svg"
                        onClick={handleClickLock}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : (
                <Box
                  component="img"
                  src="/images/unlock.svg"
                  sx={{ cursor: 'default' }}
                />
              )}
            </Box>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: 380, 
                    bgcolor: '#f5f5f5', 
                    border: '1px solid #cccccc', 
                    boxShadow: 24, 
                    p: 3, 
                    borderRadius: '8px',
                }}>
                    <Typography sx={{ fontSize: '16px' }}>
                        You are about to unlock this Database, which means that someone else can replace this Database at any point post this.
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleUnlock} style={{ backgroundColor: '#BF1D2D' }}>
                            Yes
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleClose} sx={{ ml: 2 }}>
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};



  return (
    <>
      <HeaderRoutes />
      <Box style={{ margin: isMobile ? "16px" : "30px", maxWidth: '93vw', overflowX: 'auto' }}>
        <div>
          <Grid container columns={{ xs: 8, sm: 8, md: 12 }}>
            <Grid item xs={12}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography style={{ fontSize: isMobile ? '20px' : '30px', fontWeight: 'bold' }}>
                  DBS Screen
                </Typography>
                <Button
                  className='rounded-2 text-capitalize'
                  type='submit'
                  variant="contained"
                  style={{ backgroundColor: '#BF1D2D', fontSize: isMobile ? '16px' : '18px', fontWeight: '500', width: isMobile ? '165px' : '185px', height: isMobile ? '42px' : '48px' }}
                  startIcon={<img src="/images/add-DBS.svg" alt="Icon" style={{ marginLeft: '-5px' }} />}
                >
                  + Add New DB
                </Button>
              </Box>

              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '30px' }}>
                <div>
                  <FormControl sx={{ minWidth: 146, mr: 2 }}>
                    <Select
                      sx={{ backgroundColor: '#fff' }}
                      value={database}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="ALL">All</MenuItem>
                      <MenuItem value="TEST">TEST DB</MenuItem>
                      <MenuItem value="LIVE">LIVE DB</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <SearchBox onSearch={handleSearch} sx={{ width: isMobile ? '200px' : '345px', height: '48px' }} />
                </div>
              </Box>

              <Box sx={{ maxWidth: "93vw", overflowX: "auto", background: "#FFF", margin: "45px 0px" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  sx={{
                    maxWidth: isMobile ? '90vw':'74vw', 
                    overflowX: "auto",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: 'white !important',
                      maxHeight: "70px !important",
                      minHeight: '70px !important',
                    },
                    '& .MuiDataGrid-withBorderColor': { borderColor: '#f5f5f5' },
                    '& .MuiDataGrid-cell': { cursor: 'pointer' },
                    '& .MuiDataGrid-cell:focus': { outline: 'none' },
                    '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
                  }}
                />
              </Box>

              <Box style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p style={{ fontSize: '16px' }}>
                    Showing
                    <span style={{ fontWeight: 'bold', color: '#000000', margin: '0px 5px' }}>
                      {perPage * (currentPage - 1) + 1}
                    </span>
                    to
                    <span style={{ fontWeight: 'bold', color: '#000000', margin: '0px 5px' }}>
                      {Math.min(perPage * currentPage, total)}
                    </span>
                    of
                    <span style={{ fontWeight: 'bold', color: '#000000', margin: '0px 5px' }}>
                      {total}
                    </span>
                    entries
                  </p>
                </div>
                <Pagination
                  count={Math.ceil(total / perPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: '#202020',
                      borderRadius: '10px',
                      height: '40px',
                      width: '40px',
                      fontSize: '18px',
                      fontWeight: 700,
                    },
                    '& .MuiPaginationItem-root:hover': { backgroundColor: '#EAECF0' },
                    '& .MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#414141',
                      borderRadius: '10px',
                      color: 'white',
                    },
                    '& .MuiPaginationItem-page.Mui-selected:hover': { backgroundColor: '#555555' },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <Toast
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          message={message}
          type={type}
        />
      </Box>
    </>
  );
}

export default DatabaseOverview;


