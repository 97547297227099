import { useTheme } from '@mui/material/styles';
import React from 'react'
import { Button } from '@mui/material';

export default function ButtonTheme(props)
{
    const theme = useTheme();
    let background;
    let color;
    let backgroundHover;

    if(props.color == 'primary'){
        background = theme.palette.primary.main
        backgroundHover = theme.palette.primary.light
        color = theme.palette.primary.contrastText
    }else if(props.color =='transparent'){
        background = theme.palette.transparent.main
        backgroundHover = theme.palette.transparent.light
        color = theme.palette.transparent.contrastText
    }else if(props.color =='DarkRed'){
        background = theme.palette.DarkRed.main
        backgroundHover = theme.palette.DarkRed.light
        color = theme.palette.DarkRed.contrastText
    }else if(props.color =='white'){
        background = '#D0D5DD'
        backgroundHover = theme.palette.secondary.light
        color = 'white !important'
    }else{
        background = theme.palette.secondary.main
        backgroundHover = theme.palette.secondary.light
        color = theme.palette.secondary.contrastText
    }

    return  <Button {...props}  sx={{ backgroundColor: background , color:color ,borderRadius :'0px' ,
     '&:hover': {
      backgroundColor: backgroundHover,
  },
    '&:disabled':{
        backgroundColor: 'Gray',
    }
     }} >{props.children}</Button>
}
